// src/components/ImageCarousel.tsx

import React, {useState, useEffect, useMemo} from 'react'
import {Container, Row, Col, Carousel, CarouselItem} from 'reactstrap'
import {FaChevronLeft, FaChevronRight} from 'react-icons/fa'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import ClipLoader from 'react-spinners/ClipLoader'
import debounce from 'lodash.debounce'

// Import local images
import Image1 from '../images/11.jpg'
import Image2 from '../images/22.jpg'
import Image3 from '../images/33.jpg'
import Image4 from '../images/44.jpg'
import Image5 from '../images/55.jpg'
import Image6 from '../images/66.jpg'

const defaultImages = [Image1, Image2, Image3, Image4, Image5, Image6]

interface ImageCarouselProps
{
    images?: string[]
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({images = defaultImages}) =>
{
    const [activeIndex, setActiveIndex] = useState(0)
    const [animating, setAnimating] = useState(false)
    const [itemsPerSlide, setItemsPerSlide] = useState(3)
    const [isMobile, setIsMobile] = useState(false)

    // Handle responsiveness with debounced resize
    useEffect(() =>
    {
        const handleResize = debounce(() =>
        {
            const width = window.innerWidth
            if (width < 576)
            {
                setItemsPerSlide(1)
                setIsMobile(true)
            } else if (width < 768)
            {
                setItemsPerSlide(2)
                setIsMobile(false)
            } else
            {
                setItemsPerSlide(3)
                setIsMobile(false)
            }
        }, 300) // 300ms debounce

        handleResize() // Initial check
        window.addEventListener('resize', handleResize)
        return () =>
        {
            window.removeEventListener('resize', handleResize)
            handleResize.cancel()
        }
    }, [])

    // Memoize slides with stable keys
    const slides = useMemo(() =>
    {
        const newSlides = []
        for (let i = 0; i < images.length; i += itemsPerSlide)
        {
            const slideImages = images.slice(i, i + itemsPerSlide)
            newSlides.push(
                <CarouselItem
                    onExiting={() => setAnimating(true)}
                    onExited={() => setAnimating(false)}
                    key={`carousel-item-${i}`} // Stable key
                >
                    <Row className="justify-content-center">
                        {slideImages.map((image, index) => (
                            <Col
                                key={`carousel-image-${i + index}`} // Stable key
                                md={12 / itemsPerSlide}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '5px',
                                }}
                            >
                                <LazyLoadImage
                                    src={image}
                                    alt={`Screenshot ${i + index + 1}`}
                                    effect="blur"
                                    onError={(e) =>
                                    {
                                        const target = e.target as HTMLImageElement
                                        target.onerror = null
                                        target.src = '/fallback-image.png' // Ensure this path exists
                                    }}
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        borderRadius: '8px',
                                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                                    }}
                                />
                            </Col>
                        ))}
                    </Row>
                </CarouselItem>
            )
        }
        return newSlides
    }, [images, itemsPerSlide])

    // Reset activeIndex when slides change
    useEffect(() =>
    {
        setActiveIndex(0)
    }, [slides])

    const next = () =>
    {
        if (animating) return
        const nextIndex = activeIndex === slides.length - 1 ? 0 : activeIndex + 1
        setActiveIndex(nextIndex)
    }

    const previous = () =>
    {
        if (animating) return
        const prevIndex = activeIndex === 0 ? slides.length - 1 : activeIndex - 1
        setActiveIndex(prevIndex)
    }

    return (
        <div className="carousel-container">
            {/* Global Styles */}
            <style>
                {`
                    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
                    body {
                        margin: 0;
                        padding: 0;
                        background-color: #fff;
                        font-family: 'Inter', sans-serif;
                    }
                    .carousel-container {
                        background-color: transparent;
                        padding: 20px 0;
                        position: relative; /* Relative positioning for buttons */
                    }
                    .carousel-button {
                        background-color: rgba(255, 255, 255, 0.9);
                        border: none;
                        border-radius: 50%;
                        width: 40px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        outline: none;
                        transition: background-color 0.3s, transform 0.3s;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        z-index: 2;
                        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
                    }
                    .carousel-button:hover {
                        background-color: #f0f0f0;
                    }
                    .carousel-button-prev {
                        left: 10px;
                    }
                    .carousel-button-next {
                        right: 10px;
                    }
                    /* Media Queries for Responsive Button Positioning */
                    @media (min-width: 769px) {
                        /* Desktop: Buttons outside the carousel */
                        .carousel-button-prev {
                            left: -50px;
                        }
                        .carousel-button-next {
                            right: -50px;
                        }
                    }
                    @media (min-width: 577px) and (max-width: 768px) {
                        /* Tablet: Buttons slightly outside */
                        .carousel-button-prev {
                            left: -30px;
                        }
                        .carousel-button-next {
                            right: -30px;
                        }
                    }
                    @media (max-width: 576px) {
                        /* Mobile: Buttons overlaid with adequate spacing */
                        .carousel-button-prev {
                            left: 10px;
                            transform: translateY(-50%) translateX(-50%);
                        }
                        .carousel-button-next {
                            right: 10px;
                            transform: translateY(-50%) translateX(50%);
                        }
                        .carousel-button {
                            width: 35px;
                            height: 35px;
                            background-color: rgba(255, 255, 255, 0.8);
                        }
                        .carousel-button:hover {
                            background-color: rgba(255, 255, 255, 1);
                        }
                    }
                `}
            </style>

            <Container
                style={{
                    maxWidth: '100%',
                    margin: '0 auto',
                    position: 'relative',
                    backgroundColor: 'transparent',
                }}
            >
                {/* Previous Button */}
                <button
                    onClick={previous}
                    className="carousel-button carousel-button-prev"
                    aria-label="Previous Slide"
                >
                    <FaChevronLeft size={20} color="#333" />
                </button>

                {/* Next Button */}
                <button
                    onClick={next}
                    className="carousel-button carousel-button-next"
                    aria-label="Next Slide"
                >
                    <FaChevronRight size={20} color="#333" />
                </button>

                {/* Carousel */}
                <Row className="align-items-center justify-content-center">
                    <Col xs={12}>
                        <Carousel
                            activeIndex={activeIndex}
                            next={next}
                            previous={previous}
                            interval={false}
                            ride="carousel"
                            fade={true}
                            keyboard={true}
                            pause="hover"
                            slide={true}
                        >
                            {slides}
                        </Carousel>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ImageCarousel